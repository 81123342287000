import qs from 'qs'

export const queryHomePage = () => {
  const query = qs.stringify(
    {
      fields: ['title'],
      populate: {
        titles: {
          fields: [
            'i18n_string_en',
            'i18n_string_al',
          ]
        },
        exhibition_entries: {
          populate: {
            titles: true,
            slugs: true,
            subtitles: true,
            cover_image: true
          }
        },
        education_entries: {
          populate: {
            titles: true,
            slugs: true,
          }
        },
        news_entry: {
          titles: true,
          slugs: true,
          cover_image: true
        },
        online_exhibition_image: true,
        talks_image: true,
        online_exhibition_images: true
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
