<template>
  <div
    :class="$style.root"
  >
    <PagesIndexExhibitionComponent
      v-for="exhibition in localizedData.exhibition_entries"
      :key="exhibition?.id"
      :data="exhibition"
    />

    <PagesIndexNewsComponent
      v-if="newsData?.data?.length"
      :pages="newsData?.data"
    />

    <PagesIndexPagePreviewComponent
      :title='$t("archive.title")'
      routeName="archive"
      :image="onlineArchive?.attributes"
      :invertText="true"
    />

    <PagesIndexPagePreviewComponent
      :title='$t("online_exhibitions.title")'
      :image="localizedData?.online_exhibition_image?.data?.attributes"
      :invertText="true"
      routeName="online-exhibitions"
    />

    <PagesIndexEducationComponent
      :pages="localizedData.education_entries?.data"
    />

    <PagesIndexPagePreviewComponent
      :title='$t("talks.title")'
      :image="localizedData?.talks_image?.data?.attributes"
      :invertText="true"
      routeName="talks"
    />

    <PagesIndexEditionsComponent
      :pages="editionsData?.data"
    />

    <PagesIndexNewsletterComponent />
  </div>
</template>

<script setup>
import { sample } from 'lodash-es'

const props = defineProps({
  pageData: Object,
  editionsData: {
    type: Object,
    required: false,
  },
  newsData: {
    type: Object,
    required: false,
  },
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const onlineArchive = computed(() => {
  return sample(localizedData?.value?.online_exhibition_images?.data)
})

</script>

<style module>
.root {
  position: relative;

  display: flex;
  flex-direction: column;
}
</style>
