<template>
  <div
    :class="$style.root"
  >
    <LayoutPageHeaderComponent
      :data="localizedData"
      :image="localizedData?.cover_image?.data?.attributes"
      :class="$style.header"
      routeName="exhibitions-slug"
    >
      <CoreDateTimeRangeComponent
        :dateStart="localizedData?.date_start"
        :dateEnd="localizedData?.date_end"
        :class="$style.dates"
      />
    </LayoutPageHeaderComponent>
  </div>
</template>

<script setup>

const props = defineProps({
  data: Object
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.data))
})
</script>

<style module>
.root {
  position: relative;
}

.dates {
  composes: font-size-large from global;
}
</style>
