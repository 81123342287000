<template>
  <div
    :class="$style.root"
  >
    <div :class="$style.content">
      <div :class="$style.label">
        Sign up for our newsletter
      </div>
      <input
        :class="$style.input"
        :placeholder="$t('form.email')"
        v-model="email"
        type="email"
        autocomplete="off"
        @keyup.enter="subscribe"
      />
      <input
        :class="$style.input"
        :placeholder="$t('form.name')"
        v-model="name"
        type="text"
        autocomplete="off"
        @keyup.enter="subscribe"
      />

      <button
        @click="subscribe"
        :disabled="!isValid ? '' : null"
        :class="$style.button"
      >
        {{ $t('form.submit') }}
      </button>

      <div
        v-if="res?.success === true"
      >
        {{ $t('form.success') }}
      </div>
      <div
        v-else-if="res?.success === false"
      >
        {{ $t('form.error') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
const email = ref('')
const name = ref('')

const res = ref(null)
watch(res, (val) => {
  if (val) {
    setTimeout(() => {
      res.value = null
    }, 30000)
  }
})

const isValid = computed(() => {
  return isEmail(email.value) && isLength(name.value, { min: 1 })
})

const subscribe = async (e) => {
  e.preventDefault()
  if (!isValid.value) return

  const { data, error } = await useFetch('/api/newsletter', { params: { email: email.value, name: name.value } })
  res.value = data?.value

  email.value = ''
  name.value = ''
}
</script>

<style module lang=scss>
.root {
  padding: var(--unit--vertical) var(--unit--horizontal);

  height: var(--100svh);
  position: relative;

  background-color: var(--color--gray--dark);
  color: var(--color--black);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  text-align: center;
  text-transform: uppercase;
  color: var(--color--gray);
}

.content {
  composes: container--default from global;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--unit--vertical);
}

.input {
  composes: reset-input from global;
  width: 100%;

  background: var(--color--white);

  padding: var(--padding--button-narrow);

  border-bottom: var(--stroke--medium) solid transparent;
}

.input:focus {
  border-color: var(--color--black);
}

.input::placeholder {
  color: var(--color--black);
}

.button {
  composes: reset-button link-box from global;
  margin: auto;
}

.button[disabled] {
  cursor: not-allowed;
}
</style>
