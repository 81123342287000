<template>
  <PagesIndexComponent
    :pageData="data"
    :newsData="newsData"
    :editionsData="editionsData"
  />
</template>

<script setup>
// 1
const queryPath = queryHomePage()
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPath } }
  )
})

if (!data?.value?.data?.id || error?.value) {
  throw createError({ statusCode: 404 })
}

pageSetHead(data)

// 2
const { data: editionsData } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryEditionEntryThumbnails({pageSize: 2}) } }
  )
})

// 3
const { data: newsData } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryNewsEntryThumbnails({...baseRouteGetPublicationStateQuery(), pageSize: 1}) } }
  )
})
</script>
